
import { CONTACT_MAIL, CONTACT_PHONE, WORKHOURS } from '@/main';
import Vue from 'vue';

import ImageLogo from './ImageLogo.vue';
export default Vue.extend({
	components: {
		//ImageLogo,
	},
	name: 'TheFooter',
	data: () => ({
		Workhours: WORKHOURS,
		Phone: CONTACT_PHONE,
		Email: CONTACT_MAIL,
		logo: require('@/assets/images/2023/logo_footer.svg'),
	}),
	methods: {
		footerStyle(): any {
			return { backgroundColor: this.primaryColor() };
		},
		primaryColor() {
			return this.$vuetify.theme.currentTheme.primary;
		},
	},
});
