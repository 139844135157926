
import Vue from 'vue';
import ImageLogo from './ImageLogo.vue';
export default Vue.extend({
	components: {
		ImageLogo,
	},
	name: 'DialogDisabled',
	data: () => ({
		SiteDisabled: false,
		DisabledPlaceholder: '',
	}),
	created() {
		if (location.hostname !== 'localhost' && !this.isIp) {
			fetch('https://gum-katok.ru/api/settings/')
				.then((data) => data.json())
				.then((data) => {
					this.SiteDisabled = Boolean(parseInt(data.disabled));
					this.DisabledPlaceholder = data.disabledPlaceholder;
				});
		}
	},
	computed: {
		isIp: {
			get() {
				return location.hostname.match(/\d*\.\d*\.\d*\.\d*./);
			},
		},
	},
});
