
import { DATE_SALE_END, DATE_SALE_START } from './../main';
import PriceDate from './../codebase/PriceData';
import Vue from 'vue';

export default Vue.extend({
	name: 'SchedulePrices',
	data: () => ({
		Active: [],
		yearCurrent: DATE_SALE_START.getFullYear(),
		yearNext: DATE_SALE_END.getFullYear(),
		dayStart: DATE_SALE_START.toLocaleTimeString().slice(0, 5),
		dayEnd: DATE_SALE_END.toLocaleTimeString().slice(0, 5),
		AgeRange: [
			'взрослый билет',
			'детский билет (от 7 до 12 лет)',
			'для пенсионеров (при предъявлении удостоверения или социальной карты)',
			'для детей дошкольного возраста (до 7 лет)',
			'для посетителей старше 75 лет (при предъявлении удостоверения личности)',
			'для пенсионеров',
		] as Array<string>,
	}),
	computed: {
		PriceDates: {
			get(): Array<PriceDate> {
				return [
					new PriceDate(`${this.yearCurrent}/11/30`, `${this.yearCurrent}/12/24`),
					new PriceDate(`${this.yearCurrent}/12/25`, `${this.yearNext}/01/08`),
					new PriceDate(`${this.yearNext}/01/09`, `${this.yearNext}/02/29`),
				];
			},
		},
	},
	created(): void {
		//
	},
	async mounted(): Promise<void> {
		this.PriceDates.forEach((entry: PriceDate) => {
			let date = entry.End.toLocaleDateString('ru-RU');
			fetch(`https://gum-katok.ru/api/?date=${date}`)
				.then((data) =>
					data.json().then((data) => {
						entry.Data = data;
						//console.log(entry.Data);
					})
				)
				.catch((error) => {
					console.error(error);
				});
		});
	},
});
