
import Vue from 'vue';
export default Vue.extend({
	name: 'TheIcon',
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	methods: {
		primary() {
			return this.$vuetify.theme.currentTheme.primary?.toString();
		},
	},
});
