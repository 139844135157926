
import { DATE_SALE_END, DATE_SALE_START, WORKHOURS } from '@/main';
import Vue from 'vue';

import ButtonFAQ from './buttons/ButtonFAQ.vue';

export default Vue.extend({
	name: 'SectionInfo',
	components: {
		ButtonFAQ,
	},
	data: () => ({
		image: require('./../assets/images/2022/xrink_new3.png'),
		Dialog: false,
		WorkHours: WORKHOURS,
	}),
	methods: {
		ScrollTo(selector: string) {
			let el = document.querySelector(selector);
			if (el) el.scrollIntoView();
		},
	},
	computed: {
		workingSeason() {
			// Date calculation
			let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
			let saleStart = new Date(DATE_SALE_START);
			let saleEnd = new Date(DATE_SALE_END);
			let dateUntil = new Date(new Date(saleEnd).setDate(new Date(saleEnd).getDate() - 1));
			// String formation
			let dateStart = saleStart.getDate();
			let monthStart = months[saleStart.getMonth()];
			let dateEnd = dateUntil.getDate();
			let monthEnd = months[dateUntil.getMonth()];
			return `с ${dateStart} ${monthStart} по ${dateEnd} ${monthEnd}`;
		},
	},
});
