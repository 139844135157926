
import Vue from 'vue';

export default Vue.extend({
	name: 'ImageLogo',

	props: ['small'],
	data: () => ({}),
	methods: {
		cssProps(): any {
			return {
				'--primary-color': this.$vuetify.theme.themes.light.primary,
			};
		},
	},
});
