
import Vue from 'vue';
import DataTable from './Generic/DataTable.vue';
import SchedulePrices from './SchedulePrices.vue';
import ButtonRules from './buttons/ButtonRules.vue';
import ButtonOferta from './buttons/ButtonOferta.vue';
import ButtonRent from './buttons/ButtonRent.vue';

export default Vue.extend({
	components: { DataTable, SchedulePrices, ButtonRules, ButtonRent, ButtonOferta },
	name: 'SectionSchedule',
	data: () => ({
		Sale: require('./../assets/images/2023/sale.png'),
		DialogRules: false as boolean,
		DialogRental: false as boolean,
	}),
});
