
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionPartners',
	data: () => ({
		ItemsRowOne: [
			{
				id: 0,
				image: require('@/assets/images/partners/2023/PSB.svg'),
				url: 'https://www.psbank.ru/',
				width: 204,
			},
			{
				id: 1,
				image: require('@/assets/images/partners/2023/rosgosstrah.svg'),
				url: 'https://gum-katok.pulse.insure',
				width: 287,
			},
			{
				id: 2,
				image: require('@/assets/images/partners/2023/exeed.svg'),
				url: 'https://exeed.ru/',
				width: 200,
			},
			{
				id: 3,
				image: require('@/assets/images/partners/2023/lottery.svg'),
				url: 'https://nloto.ru/',
				width: 187,
			},
		] as Array<partnerLink>,
		ItemsRowTwo: [
			{
				id: 4,
				image: require('@/assets/images/partners/2023/coolcola.svg'),
				url: 'https://ochakovo.ru/brand/coolcola/',
				width: 150,
			},
			{
				id: 5,
				image: require('@/assets/images/partners/2023/petelinka.svg'),
				url: 'https://petelinka.ru/',
				width: 110,
			},
			{
				id: 6,
				image: require('@/assets/images/partners/2023/tele2.svg'),
				url: 'https://msk.tele2.ru/',
				width: 110,
			},
			{
				id: 7,
				image: require('@/assets/images/partners/2023/Yappy.svg'),
				url: 'https://yappy.media/',
				width: 110,
			},
			{
				id: 8,
				image: require('@/assets/images/partners/2023/BOSCO.svg'),
				url: 'https://boscobrand.ru/',
				width: 245,
			},
		] as Array<partnerLink>,
	}),
});

class partnerLink {
	id!: number;
	image!: string;
	url!: string;
	width!: number;
}
