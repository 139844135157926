
import { CONTACT_MAIL, CONTACT_PHONE, WORKHOURS } from '@/main';
import Vue from 'vue';
import ImageLogo from './Generic/ImageLogo.vue';
import { EventBus } from '@/codebase/EventBus';

export default Vue.extend({
	components: { ImageLogo },
	name: 'MenuTop',

	props: ['notime'],

	data: () => ({
		ScrollTop: 0 as number,
		Small: false as boolean,
		Height: 0 as number,
		Workhours: WORKHOURS,
		Phone: CONTACT_PHONE,
		Mail: CONTACT_MAIL,
	}),

	created(): void {
		document.addEventListener('scroll', this.scroll);
	},
	mounted(): void {
		this.Height = (this.$refs.menu as HTMLElement).offsetHeight;
	},
	destroyed(): void {
		document.removeEventListener('scroll', this.scroll);
	},

	methods: {
		scroll() {
			this.ScrollTop = window.pageYOffset;
			this.Height = (this.$refs.menu as HTMLElement).offsetHeight;
			this.Small = this.ScrollTop > this.Height;
		},
		draw() {
			EventBus.$emit('draw');
		},
		styleContent(): any {
			return {
				backgroundColor: this.$vuetify.theme.themes.light.primary,
			};
		},
	},
});
