
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionInfopartners',
	data: () => ({
		Items: [
			require('@/assets/images/infopartners/2023/001.svg'),
			require('@/assets/images/infopartners/2023/003.svg'),
			require('@/assets/images/infopartners/2023/002.svg'),
		],
	}),
});
