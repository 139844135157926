
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionGallery',
	data: () => ({
		Active: [] as Array<boolean>,
		Items: [
			require('./../assets/images/gallery/2023/main/Gum_Katok_01.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_02.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_03.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_04.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_05.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_06.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_07.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_08.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_09.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_10.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_11.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_12.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_13.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_14.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_15.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_16.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_17.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_18.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_19.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_20.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_21.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_22.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_23.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_24.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_25.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_26.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_27.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_28.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_29.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_30.jpg'),
			require('./../assets/images/gallery/2023/main/Gum_Katok_31.jpg'),
		],
		Previews: [
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_01.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_02.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_03.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_04.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_05.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_06.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_07.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_08.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_09.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_10.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_11.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_12.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_13.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_14.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_15.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_16.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_17.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_18.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_19.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_20.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_21.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_22.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_23.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_24.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_25.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_26.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_27.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_28.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_29.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_30.jpg'),
			require('./../assets/images/gallery/2023/main/1x1/Gum_Katok_31.jpg'),
		],
	}),
});
