
import './style.less';

import Vue from 'vue';
import MenuTop from './components/MenuTop.vue';
import SectionButtons from './components/SectionButtons.vue';
import SectionGallery from './components/SectionGallery.vue';
import SectionInfo from './components/SectionInfo.vue';
import SectionInfopartners from './components/SectionInfopartners.vue';
import SectionMap from './components/SectionMap.vue';
import SectionPartners from './components/SectionPartners.vue';
import SectionSchedule from './components/SectionSchedule.vue';
import YandexMap from './components/YandexMap.vue';
import NavDrawer from './components/NavDrawer.vue';
import DialogDisabled from './components/Generic/DialogDisabled.vue';
import TheFooter from './components/Generic/TheFooter.vue';

export default Vue.extend({
	components: {
		MenuTop,
		SectionInfo,
		YandexMap,
		SectionButtons,
		SectionMap,
		SectionInfopartners,
		SectionGallery,
		SectionPartners,
		SectionSchedule,
		NavDrawer,
		DialogDisabled,
		TheFooter,
	},
	name: 'App',

	data: () => ({
		Banner001: require('./assets/images/banners/2023/001.png'),
		Banner003: require('./assets/images/banners/2023/003.png'),
		Banner004: require('./assets/images/banners/2023/004.png'),
		Banner005: require('./assets/images/banners/2023/005.jpg'),
	}),
});
