
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionButtons',
	data: () => ({
		buttons: [
			{ image: require('@/assets/images/buttons/2023/001.jpg'), href: '/master-class-figure/', width: 6, widthSm: 12 },
			{ image: require('@/assets/images/buttons/2023/002.jpg'), href: '/master-class-alina/', width: 6, widthSm: 12 },
			{ image: require('@/assets/images/buttons/2023/003.jpg'), href: '/master-class-fhr/', width: 12 },
		],
	}),
});
