
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class ButtonOferta extends Vue {
	modelDialog = false;
	toggleDialog() {
		this.modelDialog = true;
	}
}
