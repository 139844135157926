
import Component from 'vue-class-component';
import Vue from 'vue';
@Component
export default class ButtonFAQ extends Vue {
	modelDialog = false;

	ScrollTo(selector: string) {
		let el = document.querySelector(selector);
		if (el) el.scrollIntoView();
		this.modelDialog = false;
	}
}
