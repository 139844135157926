import IGUMProvider from "./i-gum-provider";

export default class GUMProvider implements IGUMProvider {
    private _apiUrl = "https://gum-katok.ru";

    async GetSessions(date: string, mode = "json"): Promise<Array<object>> {
        const url = `${this._apiUrl}/sessions/`;
        const r = await fetch(`${url}?mode=${mode}&date=${date}`);
        if (r.ok) {
            return r.json();
        } else {
            return [];
        }
    }
}